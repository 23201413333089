import * as React from "react";
import Layout from "../components/Layout";
import {
  faArrowCircleDown,
  faArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import EventiSlider from "../components/EventiSlider/EventiSlider";
import ProvinceTop from "../components/ProvinceTop/ProvinceTop";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";

function CollapseEcmEvidenza() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <a
        onClick={() => setOpen(!open)}
        aria-controls="destinatari"
        aria-expanded={open}
        href="#destinatari"
      >
        {open ? "Nascondi" : "Continua a leggere"}
      </a>
      <Collapse in={open}>
        <p className="text-center">
          La metodologia <strong>Asincrona</strong> permette ai discenti di fruire
          in piena autonomia del corso di formazione, purchè svolto all&acute;
          interno del periodo di sua validità.
          <br />. Solitametne sono corsi il cui materiale formativo corrisponde a
          video o dispense, la cui consultazione è obbligatoria. Al termine della
          visione dei contenuti, per ottenere il riconoscimento dei crediti ECM,
          il discente dovrà sostenre un <strong>
            test di apprendimento
          </strong>{" "}
          sotto forma di questionario con risposte multiple.
        </p>
      </Collapse>
    </>
  );
}

export default function IndexPage({ data }) {
  const {
    allEventiFad: { nodes: eventiFad },
    allEventiRes: { nodes: eventiRes },
    allEventiFsc: { nodes: eventiFsc },
  } = data;

  return (
    <Layout>
      <section className="position-relative">
        <StaticImage
          src="../images/background-fad-ecm.jpg"
          className="img-header-home"
          alt="Formazione Corsi ECM"
          title="Formazione Corsi ECM"
        />

        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1
              className="text-center text-white display-3"
              title="Catalogo corsi ECM accreditati presso Ageans"
            >
              Catalogo Corsi ECM accreditati presso Agenas
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Formazione Corsi ECM, il catalogo on line di tutti i corsi ECM
              proposti dai Provider accreditati Agenas{" "}
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <div className="container-fluid" id="sezione-corsi">
        <div className="row px-5 py-3">
          <div className="col-12">
            <div className="container">
              <h2 className="my-4 text-center titolo-sezione">
                Corsi ECM FAD in evidenza
              </h2>
              <p className="text-center">
                Con FAD si intende <b>Formazione a Distanza</b> uno dei metodi
                formativi per il conseguimento di crediti ECM, in cui le
                attività vengono effettuate da discenti senza doversi recapre
                presso la sede in cui si torva il docente o formatore. Esistono
                due metodi formativi rientranti nella categoria FAD ECM: la
                Formazione a Distanza Asincrona e quella Sincrona.
              </p>
              <CollapseEcmEvidenza />
              <p className="text-center">
                <b>
                  In questa sezione vengono mostrati tutti i corsi ECM FAD
                  accreditati in Agenas per tutte le professioni sanitarie.
                </b>
              </p>
            </div>
            <EventiSlider eventi={eventiFad} />
            <div className="row px-5 py-3">
              <div className="col-12 text-center">
                <Link to="/fad/" className="btn btn-outline-primary">
                  Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM RESIDENZIALI in evidenza
            </h2>
            <p className="text-center">
              I Corsi RES sono <b>i corsi residenziali</b>, ossia tutti gli
              eventi che
              <b>si svolgono in presenza</b>, con docenti, tutor e discenti.
              Nella formazione ECM gli eventi RES sono molto diffusi e si
              possono identificare come
              <br />
              <b>convegni</b>, <b>seminari</b>, <b>workshop</b>, <b>simposi</b>,{" "}
              <b>tavole rotonde</b> ecc.
            </p>
            <p className="text-center">
              La formazione residenziale è la{" "}
              <b>forma di insegnamento più tradizionale e classica</b> ma non
              per questo la più semplice da organizzare e gestire. Per essere
              efficace richiede uno{" "}
              <b>
                sviluppo, un’organizzazione e una gestione attenta degli
                ambienti e degli strumenti formativi
              </b>
              . Una o più giornate di formazione in aula sono un’importante
              occasione di scambio e di confronto tra professionisti dello
              stesso settore.<br></br>
              <b>
                In questa sezione vengono mostrati tutti i corsi ECM RES
                accreditati in Agenas per tutte le professioni sanitarie.
              </b>
            </p>
            <EventiSlider eventi={eventiRes} />
            <div className="row px-5 py-3">
              <div className="col-12 text-center">
                <Link to="/residenziali/" className="btn btn-outline-primary">
                  Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM FSC in evidenza
            </h2>
            <p className="text-center">
              Gli FSC sono i <b>corsi di Formazione sul Campo</b>, ciò significa
              che i professionisti sanitari vengono{" "}
              <b>formati direttamente sul campo, in maniera pratica</b>. Questi
              possono essere infatti svolti direttamente
              <br />
              in luoghi come le sale operatorie, gli ambulatori o in reparto.
            </p>
            <p className="text-center">
              La formazione sul campo si caratterizza per <b>l’utilizzo</b>, nel
              processo di apprendimento, <b>delle strutture sanitarie</b>,{" "}
              <b>
                delle competenze dei professionisti impegnati nelle attività
                cliniche e assistenziali e delle occasioni di lavoro
              </b>
              .<br />
              In altri termini l’esigenza formativa che scaturisce dal contesto
              lavorativo deve essere attuata all’interno dell’attività
              lavorativa con le{" "}
              <b>
                finalità di garantire non solo l’innalzamento della specifica
                professionalità
              </b>
              , ma anche l’
              <b>
                aumento delle capacità di ognuno di lavorare e di rapportarsi
                all’interno della propria organizzazione
              </b>
              , di migliorare le competenze dei professionisti e la qualità e
              sicurezza dell’assistenza.
            </p>
            <p>
              <strong>
                In questa sezione vengono mostrati tutti i corsi ECM FSC
                accreditati in Agenas per tutte le professioni sanitarie.
              </strong>
            </p>
            <EventiSlider eventi={eventiFsc} />
            <div className="row px-5 py-3">
              <div className="col-12 text-center">
                <Link to="/fsc/" className="btn btn-outline-primary">
                  Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-5 py-5">
        <div className="row">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <div className="card bg-dark text-white">
              <StaticImage
                src="../images/eventi-fad.jpg"
                className="card-img"
                alt="Corsi ECM FAD"
                title="Corsi ECM FAD"
              />
              <div className="card-img-overlay text-center">
                <h3 className="card-title">Corsi ECM FAD</h3>
                <p className="card-text">
                  Scopri l'offerta formativa dei corsi FAD di tutti i provider
                  accreditati in Agenas.
                </p>
                <Link to="/fad/" className="btn btn-outline-light btn-sm">
                  Vai ai corsi
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <div className="card bg-dark text-white">
              <StaticImage
                src="../images/eventi-fsc.jpg"
                className="card-img"
                alt="Corsi ECM FSC"
                title="Corsi ECM FSC"
              />
              <div className="card-img-overlay text-center">
                <h3 className="card-title">Corsi ECM FSC</h3>
                <p className="card-text">
                  Scopri l'offerta formativa dei corsi FSC di tutti i provider
                  accreditati in Agenas.
                </p>
                <Link to="/fsc/" className="btn btn-outline-light btn-sm">
                  Vai ai corsi
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <div className="card bg-dark text-white">
              <StaticImage
                src="../images/eventi-res.jpg"
                className="card-img"
                alt="Corsi ECM Residenziali"
                title="Corsi ECM Residenziali"
              />
              <div className="card-img-overlay text-center">
                <h3 className="card-title">Corsi ECM RES</h3>
                <p className="card-text">
                  Scopri l'offerta formativa dei corsi RES di tutti i provider
                  accreditati in Agenas.
                </p>
                <Link
                  to="/residenziali/"
                  className="btn btn-outline-light btn-sm"
                >
                  Vai ai corsi
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
            <div className="card bg-dark text-white">
              <StaticImage
                src="../images/eventi-blended.jpg"
                className="card-img"
                alt="Corsi ECM BLENDED"
                title="Corsi ECM BLENDED"
              />
              <div className="card-img-overlay text-center">
                <h3 className="card-title">Corsi ECM BLENDED</h3>
                <p className="card-text">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content.
                </p>
                <a href="#" className="btn btn-outline-light btn-sm">
                  Vai ai corsi
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ProvinceTop />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Rimani sempre aggiornato sulle ultime novità del Mondo ECM!"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Vuoi rimanere sempre aggiornato sulle novità del Mondo ECM?
              <br />
              <span style={{ fontSize: 14 }}>
                I tuoi dati non verranno mai condivisi con nessun Provider!
              </span>
            </h4>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    allEventiFad: allEventiJson(
      limit: 11
      filter: { tipo_evento: { eq: "FAD" } }
      sort: { fields: data_fine_timestamp, order: DESC }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        quota_partecipazione
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiRes: allEventiJson(
      limit: 11
      filter: { tipo_evento: { eq: "RES" } }
      sort: { fields: data_fine_timestamp, order: DESC }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        quota_partecipazione
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiFsc: allEventiJson(
      limit: 11
      filter: { tipo_evento: { eq: "FSC" } }
      sort: { fields: data_fine_timestamp, order: DESC }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        quota_partecipazione
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;

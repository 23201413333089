import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const provinceTopData = [
  {
    image: (
      <StaticImage
        src="../../images/province/napoli.jpg"
        className="card-img"
        alt="Corsi ECM Residenziali Napoli"
        width={500}
        height={300}
      />
    ),
    provincia: "Napoli",
    text: "Scopri i corsi ed i convegni ECM svolti in presenza nella provincia di Napoli.",
    link: "/residenziali/napoli/",
  },
  {
    image: (
      <StaticImage
        src="../../images/province/roma.jpg"
        className="card-img"
        alt="Corsi ECM Residenziali Roma"
        width={500}
        height={300}
      />
    ),
    provincia: "Roma",
    text: "Scopri i corsi ed i convegni ECM svolti in presenza nella provincia di Roma.",
    link: "/residenziali/roma/",
  },
  {
    image: (
      <StaticImage
        src="../../images/province/milano.jpg"
        className="card-img"
        alt="Corsi ECM Residenziali Milano"
        width={500}
        height={300}
      />
    ),
    provincia: "Milano",
    text: "Scopri i corsi ed i convegni ECM svolti in presenza nella provincia di Milano.",
    link: "/residenziali/milano/",
  },
  {
    image: (
      <StaticImage
        src="../../images/province/torino.jpg"
        className="card-img"
        alt="Corsi ECM Residenziali Torino"
        width={500}
        height={300}
      />
    ),
    provincia: "Torino",
    text: "Scopri i corsi ed i convegni ECM svolti in presenza nella provincia di Torino",
    link: "/residenziali/torino/",
  },
];

export default function ProvinceTop() {
  return (
    <div className="container-fluid p-5">
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-2 g-xl-3">
        {provinceTopData.map(({ image, provincia, text, link }) => (
          <div key={provincia} className="col">
            <div class="card bg-dark text-white">
              {image}
              <div class="card-img-overlay">
                <h4 class="card-title">{provincia}</h4>
                <p class="card-text">{text}</p>
                <Link to={link} className="btn btn-outline-light">
                  Vedi
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
